<template>
  <main class="tour-success w-100">
    <section class="tour-success__container">
      <h1 class="title tour-success__title">{{ $t("Invitation accepted") }}</h1>

      <div class="tour-success__check"></div>

      <p class="text tour-success__text">{{ $t("The order can be tracked") }}</p>

      <a class="link tour-success__link" href>{{ $t("In the personal area") }}</a>
    </section>
  </main>
</template>

<script>
export default {
  name: "TourOrder6",
};
</script>